import { useQuery } from "@tanstack/react-query";
import useGetAuthCookie from "hooks/useGetAuthCookie";
import { RestructuredGetShopOffersResponse } from "types/responses";
import { getShopOffers } from "api/shop";

const useGetShopOffers = (refetchInterval: number = 0) => {
  const cookie = useGetAuthCookie();

  return useQuery<RestructuredGetShopOffersResponse, Error>(
    ["shop-offers"],
    async () => {
      return getShopOffers();
    },
    {
      enabled: Boolean(cookie),
      refetchInterval,
    },
  );
};

export default useGetShopOffers;
