import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import tracePro from "assets/images/shop/trace-pro.webp";
import traceProMobile from "assets/images/shop/trace-pro-mobile.webp";

import useGetGemsOffers from "hooks/requests/gameShop/useGetGemsOffers";
import useGetProfile from "hooks/requests/useGetProfile";
import { useAppDispatch } from "hooks/store";
import { openGameShopVendorChoseModal } from "store/modalsSlice";

import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Show from "components/common/Show";

import s from "./ControlPart.module.scss";

function ControlPart() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: profile, isLoading: isProfileLoading } = useGetProfile();
  const { data } = useGetGemsOffers();

  const offer = useMemo(() => data?.subscriptions[0], [data]);

  const openModal = () => {
    dispatch(openGameShopVendorChoseModal({ codename: "trace_pro" }));
  };

  return (
    <div className={s.controlPart}>
      {window.innerWidth > 400 && (
        <img src={tracePro} alt="trace-pro" className="fill-cover" />
      )}
      {window.innerWidth <= 400 && (
        <img
          src={traceProMobile}
          alt="trace-pro"
          className={cn("fill-cover", s.backgroundMobile)}
        />
      )}
      <div className={s.content}>
        <h3 className={s.title}>
          <span
            dangerouslySetInnerHTML={{ __html: t("gameShop.control.title1") }}
          />{" "}
          <Icon variant="crown" className={s.crownIcon} />
          <span
            dangerouslySetInnerHTML={{ __html: t("gameShop.control.title2") }}
          />
        </h3>
        <div className={s.controls}>
          <Show>
            <Show.When
              isTrue={!profile?.have_subscription && !isProfileLoading}
            >
              <div className={s.pricePart}>
                <span className={s.priceBeforeDiscount}>${offer?.price}</span>
                <span className={s.price}>${offer?.discountPrice}</span>
              </div>
            </Show.When>
          </Show>
          <Show>
            <Show.When
              isTrue={!profile?.have_subscription && !isProfileLoading}
            >
              <div className={s.buttons}>
                <Button className={s.button} onClick={openModal}>
                  {t("actions.buy")}
                </Button>
              </div>
            </Show.When>
            <Show.When
              isTrue={!!profile?.have_subscription && !isProfileLoading}
            >
              <div className={s.status}>
                <span className={s.statusText}>
                  {t("gameShop.control.status")}
                </span>
                <Icon variant="check" className={s.checkIcon} />
              </div>
            </Show.When>
            <Show.Else>
              <div className={s.loadingFiller}>
                <div className={s.buttons}>
                  <Button className={s.button} onClick={openModal}>
                    {t("actions.buy")}
                  </Button>
                </div>
              </div>
            </Show.Else>
          </Show>
        </div>
      </div>
    </div>
  );
}

export default ControlPart;
