import { useMutation } from "@tanstack/react-query";
import { purchaseGemsOffer } from "api/shop";
import ApiError from "classes/ApiError";
import { useAppDispatch } from "hooks/store";
import { openShopCompleteModal } from "store/modalsSlice";
import { PurchaseGemsOfferRequest } from "types/requests";
import { showErrors } from "utils/showErrors";

const usePurchaseGemsOffer = () => {
  const dispatch = useAppDispatch();

  const queryInfo = useMutation<undefined, ApiError, PurchaseGemsOfferRequest>({
    mutationKey: ["purchase_gems"],
    mutationFn: (payload) => purchaseGemsOffer(payload),
    onSuccess: (data, args) => {
      dispatch(openShopCompleteModal({ codename: `${args.gems}` }));
    },
    onError: (error) => {
      showErrors(error);
    },
  });

  return queryInfo;
};

export default usePurchaseGemsOffer;
