import ApiError from "classes/ApiError";
import {
  PurchaseGemsOfferRequest,
  PurchaseSubscriptionRequest,
} from "types/requests";
import {
  ApiErrorResponse,
  GetShopOffersResponse,
  RestructuredGetShopOffersResponse,
} from "types/responses";
import { convertFieldsToString } from "utils/convertJSONFields";
import restructureGameShopOffersResponse from "utils/restructureGameShopOffersResponse";

/* eslint-disable import/prefer-default-export */
const endpoint = process.env.REACT_APP_API_ENDPOINT || "";

export const getShopOffers =
  async (): Promise<RestructuredGetShopOffersResponse> => {
    const response = await fetch(`${endpoint}/gameshop/offers`, {
      credentials: "include",
      method: "GET",
    });

    if (!response.ok) {
      const errorData: ApiErrorResponse = await response.json();
      throw new ApiError(errorData.errors, response.status);
    }

    const stringData = await response.text();

    const convertedData = convertFieldsToString(stringData, ["matic", "usdt"]);

    const dataRaw: GetShopOffersResponse = JSON.parse(convertedData);
    const newData = restructureGameShopOffersResponse(dataRaw);
    return newData;
  };

export async function purchaseGemsOffer({
  currency,
  gems,
}: PurchaseGemsOfferRequest) {
  const response = await fetch(`${endpoint}/gameshop/offers/gems/purchase`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      gems,
      currency,
    }),
  });

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();

  return data;
}

export async function purchaseSubscription({
  currency,
}: PurchaseSubscriptionRequest) {
  const response = await fetch(
    `${endpoint}/gameshop/offers/subscription/purchase`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        currency,
      }),
    },
  );

  if (!response.ok) {
    const errorData: ApiErrorResponse = await response.json();
    throw new ApiError(errorData.errors, response.status);
  }

  const data = await response.json();

  return data;
}
