import { useState, useEffect, useId } from "react";

import {
  setTaskRequest,
  finishTaskRequest,
  changeTaskRequestStage,
} from "store/modalsSlice";
import { useAppDispatch, useAppSelector } from "./store";

interface Params<ResolveT, RejectT> {
  openModal: (args?: any) => void;
  onResolve: (args?: ResolveT) => Promise<void> | void;
  onReject?: (args?: RejectT) => void;
}

const useAwaitModalResponse = <ResolveT = unknown, RejectT = unknown>({
  onResolve,
  openModal,
  onReject = () => {},
}: Params<ResolveT, RejectT>) => {
  const thisRequestId = useId();
  const dispatch = useAppDispatch();
  const { stage, task, data } = useAppSelector(
    (state) => state.modals.modalTaskRequest,
  );
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    const checkChanges = async () => {
      if (!isWaiting || !task || task !== thisRequestId) return;
      if (stage === "resolved") {
        try {
          const result = onResolve(data);
          if (result instanceof Promise) {
            dispatch(changeTaskRequestStage("resolving"));
            await result;
          }
          setIsWaiting(false);
          dispatch(finishTaskRequest());
        } catch (error) {
          dispatch(changeTaskRequestStage("pending"));
        }
      }
      if (stage === "rejected") {
        onReject(data);
        setIsWaiting(false);
        dispatch(finishTaskRequest());
      }
      if (stage === "idle") {
        setIsWaiting(false);
      }
    };
    checkChanges();
  }, [
    data,
    dispatch,
    isWaiting,
    onReject,
    onResolve,
    stage,
    task,
    thisRequestId,
  ]);

  return (args?: any) => {
    setIsWaiting(true);
    dispatch(setTaskRequest({ taskId: thisRequestId }));
    openModal(args);
  };
};

export default useAwaitModalResponse;
