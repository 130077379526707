enum ProjectPath {
  MARKET = "market",
  MARKET_CAR = "market/car",
  MARKET_PET = "market/pet",
  MARKET_PASS = "market/pass",
  MARKET_PART = "market/part",
  MARKET_TOWN = "market/town",
  MARKET_ITEM = "market/item",
  WALLET = "wallet",
  WALLET_NFT = "wallet/nft",
  WALLET_SEND = "wallet/send",
  WALLET_TRANSFER = "wallet/transfer",
  WALLET_SELL = "wallet/sell",
  WALLET_SWAP = "wallet/swap",
  DASHBOARD = "dashboard",
  LOOTBOXES = "lootboxes",
  INVITE = "invite",
  GAME_SHOP = "shop",
  BURN_PASS = "genesis",
  TOKENS = "tokens",

  ADVERTISEMENT = "ads",

  TOWNS = "towns",

  ECO_TRACE = "https://eco.metatrace.me/",
  WHITEPAPER = "https://wiki.metatrace.me/",
  REAL_WORLD = "https://metatrace.me/",
  MARKETPLACE = "MARKETPLACE",
}

export enum CommunityLinks {
  TWITTER = "https://twitter.com/trace_meta",
  TELEGRAM = "https://t.me/MetaTrace",
  DISCORD = "https://discord.com/invite/metatrace",
}

export default ProjectPath;
