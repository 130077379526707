/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import cn from "classnames";

import useGetGemsOffers from "hooks/requests/gameShop/useGetGemsOffers";
import { useAppDispatch } from "hooks/store";

import Button from "components/common/Button";
import few_gems from "assets/images/shop/few_gems.webp";
import bunch_gems from "assets/images/shop/bunch_gems.webp";
import box_gems from "assets/images/shop/box_gems.webp";
import bag_gems from "assets/images/shop/bag_gems.webp";
import bucket_gems from "assets/images/shop/bucket_gems.webp";
import small_chest_gems from "assets/images/shop/small_chest_gems.webp";
import big_chest_gems from "assets/images/shop/big_chest_gems.webp";
import safe_gems from "assets/images/shop/safe_gems.webp";
import sale from "assets/images/shop/sale.webp";
import beams from "assets/images/common/beamsStronger.svg";

import { openGameShopVendorChoseModal } from "store/modalsSlice";
import { GameShopOffer } from "types/shop";

import s from "./GemsPart.module.scss";

const gemsImages = {
  few_gems,
  bunch_gems,
  box_gems,
  bag_gems,
  bucket_gems,
  small_chest_gems,
  big_chest_gems,
  safe_gems,
};

function GemElement({
  type,
  value,
  price,
  currencyPrices,
  discount,
  discountPrice,
}: GameShopOffer) {
  const dispatch = useAppDispatch();

  const openModal = () => {
    dispatch(openGameShopVendorChoseModal({ codename: `${value}` }));
  };

  return (
    <div className={cn(s.gemElement)}>
      <img src={beams} alt="beams" className={cn(s.beams)} />
      <div className={s.glow} />
      <div className={s.shadow} />
      <img
        src={gemsImages[type as keyof typeof gemsImages]}
        alt={`gem-${value}`}
        className="fill-cover"
      />
      <div className={s.title}>{value} GEM</div>
      {discount && discountPrice && (
        <div className={s.buttonWrapper}>
          <div className={s.discountWrapper}>
            <img src={sale} alt="sale" className="fill-cover" />
            <span className={s.discount}>-{discount}%</span>
          </div>
          <Button className={s.button} onClick={openModal}>
            <span className={s.priceBeforeDiscount}>{price}</span>
            <span className={s.price}>${discountPrice}</span>
          </Button>
        </div>
      )}
      {!discount && !discountPrice && (
        <Button className={s.button} onClick={openModal}>
          <span className={s.price}>${price}</span>
        </Button>
      )}
    </div>
  );
}

function GemsPart() {
  const { data } = useGetGemsOffers();

  return (
    <section className={s.gemsPart}>
      <div className={s.titlePart}>
        <div className={s.line} />
        <div className={s.title}>GEMS</div>
        <div className={cn(s.line, s.reverse)} />
      </div>
      <div className={s.gems}>
        {data?.gems.map((elem) => (
          <GemElement {...elem} key={elem.value} />
        ))}
      </div>
    </section>
  );
}

export default GemsPart;
