import React from "react";
import { useTranslation } from "react-i18next";

import ShopCompleteModal from "components/modals/ShopCompleteModal";
import GameShopVendorChoseModal from "components/modals/GameShopVendorChoseModal";
import ControlPart from "./ControlPart";
import GemsPart from "./GemsPart";

import s from "./GameShopPage.module.scss";

function GameShopPage() {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.gameShop}>
        <div className={s.container}>
          <h1 className={s.title}>{t("gameShop.title")}</h1>
          <ControlPart />
          <GemsPart />
        </div>
      </div>
      <GameShopVendorChoseModal />
      <ShopCompleteModal />
    </>
  );
}

export default GameShopPage;
