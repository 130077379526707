/* eslint-disable react/no-danger */
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/store";
import { closeShopCompleteModal } from "store/modalsSlice";

import Button from "components/common/Button";
import Icon from "components/common/Icon";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./ShopCompleteModal.module.scss";

function ShopCompleteModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpen, offerCodename } = useAppSelector(
    (state) => state.modals.shopCompleteModal,
  );

  const isSubscriptionOffer = useMemo(
    () => offerCodename === "trace_pro",
    [offerCodename],
  );

  const close = useCallback(() => {
    dispatch(closeShopCompleteModal());
  }, [dispatch]);

  const offerName = isSubscriptionOffer
    ? "Trace Pack"
    : `${offerCodename.toUpperCase()} gems`;

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("gameShop.completeModal.title")}
    >
      <div className={s.content}>
        <div className={s.textRow}>
          <div className={s.checkIconWrapper}>
            <Icon variant="check" className={s.checkIcon} />
          </div>
          <div
            className={s.text}
            dangerouslySetInnerHTML={{
              __html: `<span>
                  ${t("gameShop.completeModal.text", { offer: offerName })}
                  ${
                    isSubscriptionOffer
                      ? t("gameShop.completeModal.subscriptionAdditional")
                      : ""
                  }
                </span>`,
            }}
          />
        </div>
        <Button className={s.additionalCloseButton} onClick={close}>
          {t("gameShop.completeModal.button")}
        </Button>
      </div>
    </ModalContainerWithActions>
  );
}

export default ShopCompleteModal;
