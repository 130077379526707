import { useMutation, useQueryClient } from "@tanstack/react-query";

import ApiError from "classes/ApiError";
import { useAppDispatch } from "hooks/store";
import { showErrors } from "utils/showErrors";
import { openShopCompleteModal } from "store/modalsSlice";
import { GetProfileResponse } from "types/responses";
import { purchaseSubscription } from "api/shop";

const usePurchaseSubscription = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const queryInfo = useMutation<
    undefined,
    ApiError,
    { currency: "matic" | "usdt" }
  >({
    mutationKey: ["make-subscription"],
    mutationFn: (payload) => purchaseSubscription(payload),
    onSuccess: (data) => {
      queryClient.setQueryData<GetProfileResponse>(["profile"], (profile) => {
        if (!profile || !data) return profile;

        return {
          ...profile,
          have_subscription: true,
          // expire_at: `${data.expire_at}`,
        };
      });
      dispatch(openShopCompleteModal({ codename: "trace-pro" }));
    },
    onError: (error) => {
      showErrors(error);
    },
  });

  return queryInfo;
};

export default usePurchaseSubscription;
