import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  closeCommonModal,
  closeGameShopVendorChoseModal,
  openCommonModal,
} from "store/modalsSlice";
import { useAppDispatch, useAppSelector } from "hooks/store";
import useGetGemsOffers from "hooks/requests/gameShop/useGetGemsOffers";
import usePurchaseGemsOffer from "hooks/requests/gameShop/usePurchaseGemsOffer";
import useAwaitModalResponse from "hooks/useAwaitModalResponse";
import usePurchaseSubscription from "hooks/requests/gameShop/usePurchaseSubscription";

import Coin from "components/Coin";
import ModalContainerWithActions from "../ModalContainerWithActions";

import s from "./GameShopVendorChoseModal.module.scss";

function GameShopVendorChoseModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data } = useGetGemsOffers();
  const { mutateAsync: purchaseGemsOffer } = usePurchaseGemsOffer();
  const { mutateAsync: purchaseSubscription } = usePurchaseSubscription();
  const { isOpen, offerCodename } = useAppSelector(
    (state) => state.modals.gameShopVendorChoseModal,
  );

  const [chosenCurrencyLocal, setChosenCurrencyLocal] = useState<
    "matic" | "usdt"
  >("matic");

  const close = useCallback(() => {
    dispatch(closeGameShopVendorChoseModal());
  }, [dispatch]);

  const isSubscriptionOffer = useMemo(
    () => offerCodename === "trace_pro",
    [offerCodename],
  );

  const offer = useMemo(() => {
    if (isSubscriptionOffer) return data?.subscriptions[0];
    return data?.gems.find((elem) => `${elem.value}` === offerCodename);
  }, [data, offerCodename, isSubscriptionOffer]);

  const onResolveLocal = async () => {
    if (!offer) {
      dispatch(closeCommonModal());
      return;
    }
    if (isSubscriptionOffer) {
      await purchaseSubscription({ currency: chosenCurrencyLocal });
    } else {
      await purchaseGemsOffer({
        currency: chosenCurrencyLocal,
        gems: offer.value,
      });
    }
    dispatch(closeCommonModal());
    close();
  };

  const openBuyModal = useAwaitModalResponse({
    openModal: (chosenCurrency: "matic" | "usdt") => {
      const offerName = isSubscriptionOffer
        ? "Trace pack"
        : `${offer?.value} gems`;

      const offerPrice = `${
        offer?.currencyPrices[`${chosenCurrency}Converted`]
      } ${chosenCurrency.toUpperCase()}`;

      const title = t("gameShop.confirmationModal.title", {
        offer: offerName,
      });

      const text = t("gameShop.confirmationModal.text", {
        offer: offerName,
        price: offerPrice,
      });

      dispatch(
        openCommonModal({
          title,
          text,
          isResolveButton: true,
          resolveButtonText: t("gameShop.buyModal.buttonBuy"),
          isRejectButton: true,
          rejectButtonText: t("gameShop.buyModal.buttonCancel"),
          closeAfterResolveReject: false,
        }),
      );
    },
    onResolve: async () => {
      await onResolveLocal();
    },
    onReject: () => {
      dispatch(closeCommonModal());
    },
  });

  const onVendorClick = (vendor: "matic" | "usdt") => () => {
    setChosenCurrencyLocal(vendor);
    openBuyModal(vendor);
  };

  return (
    <ModalContainerWithActions
      open={isOpen}
      close={close}
      title={t("gameShop.vendorModal.title", {
        offer: isSubscriptionOffer ? "Trace pack" : `${offer?.value} gems`,
      })}
    >
      <div className={s.modal}>
        <div className={s.vendors}>
          <div className={s.container}>
            <p className={s.title}>USDT</p>
            <div className={s.rightPart}>
              {offer && (
                <div className={s.price}>
                  {offer?.currencyPrices.usdtConverted}
                </div>
              )}
              <Coin
                currency="usdt"
                variant="outerRing"
                wrapperClassName={s.coinWrapper}
                className={s.coin}
              />
            </div>
            <button
              type="button"
              onClick={onVendorClick("usdt")}
              className="fill-link"
            >
              buy
            </button>
          </div>
          <div className={s.container}>
            <p className={s.title}>MATIC</p>
            <div className={s.rightPart}>
              {offer && (
                <div className={s.price}>
                  {offer?.currencyPrices.maticConverted}
                </div>
              )}
              <Coin
                currency="matic"
                variant="outerRing"
                wrapperClassName={s.coinWrapper}
                className={s.coin}
              />
            </div>
            <button
              type="button"
              onClick={onVendorClick("matic")}
              className="fill-link"
            >
              buy
            </button>
          </div>
        </div>
      </div>
    </ModalContainerWithActions>
  );
}

export default GameShopVendorChoseModal;
