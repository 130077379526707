import { CoinType, JustCoinType } from "./coins";
import { ILootboxItem } from "./lootboxes";
import { TokenAddress } from "./listing";

export interface CommonModalState {
  isOpen: boolean;
  title: string;
  text: string;
  closeAfterResolveReject?: boolean;
  resolveButtonText?: string;
  isResolveButton?: boolean;
  rejectButtonText?: string;
  isRejectButton?: boolean;
}

export interface CommonModalOpenPayload {
  title: string;
  text: string;
  closeAfterResolveReject?: boolean;
  resolveButtonText?: string;
  isResolveButton?: boolean;
  rejectButtonText?: string;
  isRejectButton?: boolean;
}

interface AuthModalState {
  isOpen: boolean;
  stage: AuthModalStage;
  email: string;
  mnemonic: string[];
  mnemonicString: string;
}

interface WalletActionModalState {
  isOpen: boolean;
  entity: `${JustCoinType}`;
}

interface WalletSelectTransferTypeModalState {
  isOpen: boolean;
}

interface WalletSwapModalState {
  isOpen: boolean;
}

export enum EWalletSwapCoinChooseModalType {
  from = "from",
  to = "to",
}

interface WalletSwapCoinChooseModalState {
  isOpen: boolean;
  type: EWalletSwapCoinChooseModalType;
}
interface WalletSuccessfulPurchaseModalState {
  isOpen: boolean;
}

interface WalletSuccessfulSellingModalState {
  isOpen: boolean;
}

interface WalletWarningListingModalState {
  isOpen: boolean;
  forbiddenCoinTypeListings: Array<TokenAddress>;
}

type NFTCollectionsAllowed = {
  [K in TokenAddress]?: boolean;
};

export interface BalanceModalFields {
  withNFT?: NFTCollectionsAllowed;
  withACE?: boolean;
  withTRC?: boolean;
  withMATIC?: boolean;
  withUSDT?: boolean;
  withWBTC?: boolean;
  withWETH?: boolean;
  stage?: BalanceModalStage;
}

export type BalanceModalStage = "main" | "NFTSelector";

export interface BalanceModalState extends BalanceModalFields {
  isOpen: boolean;
  stage: BalanceModalStage;
}

export interface ConfirmationModalFields {
  title: string;
  buttonText: string;
  text?: string;
}

export interface ConfirmationModalState extends ConfirmationModalFields {
  isOpen: boolean;
}

export interface ActionModalFields {
  title: string;
  buttonText: string;
  input?: {
    coin?: `${JustCoinType}`;
    initialValue: string | number;
    placeHolder?: string;
  };
}

export interface ActionModalState extends ActionModalFields {
  isOpen: boolean;
}

export type AuthModalStage =
  | "logIn"
  | "verifyCode"
  | "verifyTelegramCode"
  | "referralCode"
  | "referralCodeRegister"
  | "profileInfo"
  | "register"
  | "mnemonic";

export interface SortModal {
  isOpen: boolean;
}
export interface LootBoxWinningModal {
  isOpen: boolean;
  gift: ILootboxItem | undefined;
}

export enum ModalTaskRequestStage {
  idle = "idle",
  pending = "pending",
  resolved = "resolved",
  resolving = "resolving",
  rejected = "rejected",
}

export interface ModalTaskCompletePayload {
  result:
    | `${ModalTaskRequestStage.rejected}`
    | `${ModalTaskRequestStage.resolved}`;
  data?: any;
}

export interface ModalTaskRequest {
  stage: `${ModalTaskRequestStage}`;
  task?: string;
  data?: any;
}

export interface FillUpModalState {
  isOpen: boolean;
}

export interface WelcomeBuyModalState {
  isOpen: boolean;
}

export interface WelcomeWideModalState {
  isOpen: boolean;
}

export interface AuthConfirmationModal {
  isOpen: boolean;
}

export interface MoonPayModalState {
  isOpen: boolean;
}

export interface BuyTraceProModalState {
  isOpen: boolean;
}

export interface ShopCompleteModalState {
  isOpen: boolean;
  offerCodename: string;
}

export interface TokensModalState {
  isOpen: boolean;
}

export interface GameShopVendorChoseState {
  isOpen: boolean;
  offerCodename: string;
}

export interface InitialState {
  commonModal: CommonModalState;
  logInModal: AuthModalState;
  walletActionModal: WalletActionModalState;
  walletSelectTransferTypeModal: WalletSelectTransferTypeModalState;
  confirmationModal: ConfirmationModalState;
  balanceModal: BalanceModalState;
  actionModal: ActionModalState;
  fillUpModal: FillUpModalState;
  sortModal: SortModal;
  authConfirmationModal: AuthConfirmationModal;
  lootBoxWinningModal: LootBoxWinningModal;
  modalTaskRequest: ModalTaskRequest;
  moonPayModal: MoonPayModalState;
  walletSwapModal: WalletSwapModalState;
  walletSwapCoinChooseModal: WalletSwapCoinChooseModalState;
  walletSuccessfulPurchaseModal: WalletSuccessfulPurchaseModalState;
  walletSuccessfulSellingModal: WalletSuccessfulSellingModalState;
  walletWarningListingModal: WalletWarningListingModalState;
  welcomeBuyModal: WelcomeBuyModalState;
  welcomeWideModal: WelcomeWideModalState;
  buyTraceProModal: BuyTraceProModalState;
  shopCompleteModal: ShopCompleteModalState;
  tokensModal: TokensModalState;
  gameShopVendorChoseModal: GameShopVendorChoseState;
}
