import {
  GetShopOffersResponse,
  RestructuredGetShopOffersResponse,
} from "types/responses";
import { CurrencyPrices, CurrencyPricesRestructured } from "types/shop";

import { convertDecimalTokenShort } from "./convertDecimalToken";

const restructureCurrencyPrices = (
  prices: CurrencyPrices,
): CurrencyPricesRestructured => {
  return {
    ...prices,
    maticConverted: convertDecimalTokenShort(prices.matic, "matic"),
    usdtConverted: convertDecimalTokenShort(prices.usdt, "usdt"),
  };
};

const restructureGameShopOffersResponse = (
  data: GetShopOffersResponse,
): RestructuredGetShopOffersResponse => {
  const result: RestructuredGetShopOffersResponse = {
    gems: data.gems.map((elem) => ({
      ...elem,
      currencyPrices: restructureCurrencyPrices(elem.currencyPrices),
    })),
    subscriptions: data.subscriptions.map((elem) => ({
      ...elem,
      currencyPrices: restructureCurrencyPrices(elem.currencyPrices),
    })),
  };

  return result;
};

export default restructureGameShopOffersResponse;
